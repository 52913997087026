<template>
  <div :class="`eligibility-questionnaire ${$isMobile ? 'is-mobile pt-embedded-in-ios' : 'pt-6'}`">
    <NavBack text="Back" :action="goBack"></NavBack>
    <MenuAndLogo v-if="$isMobile" referralIconBackgroundColor="#fff"></MenuAndLogo>
    <section class="content-wrapper">
      <div class="page-title has-text-weight-semibold has-text-centered mb-5">Eligibility Questionnaire</div>
      <Questionnaire
        :onLoad="questionnaireOnLoad"
        :onChange="questionnaireOnChange"
        type="eligibility"
      ></Questionnaire>
    </section>
    <footer v-if="showBottomButtons" class="footer-buttons">
      <BottomButtons
        backButtonText="Back"
        :nextButtonText="fromProfilePage ? 'Save' : 'Next'"
        :nextDisabled="!questionnaireCompleted"
        :loading="nextButtonIsLoading"
        :showSteps="false"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </footer>
    <DisclaimerModal
      @closeDisclaimerModal="closeDisclaimerModal"
      type="atlasOneQuestionnaireDisclaimer"
      v-if="showDisclaimer"
    ></DisclaimerModal>
    <ConfirmModal
      title="Congratulations"
      :text="eligibilityResultModalText"
      :buttonText="eligibilityResultButtonText"
      :showModal="showEligibilityResultModal"
      @closeModal="closeEligibilityResultModal"
      :hasCloseIcon="false"
      :hasClickableBackground="false"
    >
      <lottie-player
        class="confetti"
        autoplay
        mode="normal"
        speed="0.5"
        src="https://assets10.lottiefiles.com/packages/lf20_wrcc9v6e.json"
        style="width: 100%; height: 280px;"
        v-if="showEligibilityResultModal"
      >
      </lottie-player>
    </ConfirmModal>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import Questionnaire from '@views/profile/questionnaire/QuestionsForm.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import questionnaireMixins from '@utils/mixins/questionnaire-mixins.js'
import DisclaimerModal from '@components/modal/disclaimer-modal.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { saveQuestionnaire } from '@api/profile'
import { embeddedInIos } from '@utils/common-methods/common'
import '@lottiefiles/lottie-player'

export default {
  mixins: [questionnaireMixins],
  data() {
    return {
      nextButtonIsLoading: false,
      showDisclaimer: false,
      showEligibilityResultModal: false,
    }
  },
  components: {
    NavBack,
    Questionnaire,
    MenuAndLogo,
    BottomButtons,
    DisclaimerModal,
    ConfirmModal
  },
  computed: {
    nameSpaceUrl() {
      return this.$route.query.distributor
    },
    propertyId() {
      return this.$route.query.propertyId
    },
    fromProfilePage() {
      return !this.propertyId
    },
    suitabilityAnswers() {
      return localStorage.getItem('suitabilityAnswers')
    },
    eligibilityStatus() {
      if (!this.answers) return
      if ((this.answers.net_assets === 'Yes' ||
        this.answers.net_income_single === 'Yes' ||
        this.answers.net_income_with_spouse === 'Yes') &&
        this.answers.less_than_30k_in_past_12_months === 'Yes') return 'eligible'
      if (this.answers.less_than_10k_in_past_12_months === 'Yes' ||
        this.answers.less_than_30k_in_past_12_months === 'Yes') return 'non-eligible'
      return 'non-eligible-limited'
    },
    eligibilityResultModalText() {
      if (!this.answers) return
      if (this.eligibilityStatus === 'eligible') return 'As an Eligible Investor, you can invest up to $30,000 in a rolling 12 month period through the addy platform. A representative may reach out via email if they have further questions.'
      else if (this.eligibilityStatus === 'non-eligible') return 'As a non-Eligible Investor, you can invest up to $10,000 in a rolling 12 month period in securities offered through Offering Memorandums. A representative may reach out via email if they have further questions.'
      const limitAmount = this.answers.less_than_30k_in_past_12_months === 'No'
      return `As a non-Eligible Investor who has invested more than ${limitAmount ? '$30,000' : '$10,000'} in securities offered through Offering Memorandums in the trailing 12 months, you can invest up to $100 in this investment due to securities regulations in your province.`
    },
    eligibilityResultButtonText() {
      return this.fromProfilePage ? 'OK' : 'Invest'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'suitabilityQuestionnaire') localStorage.removeItem('suitabilityAnswers')
    next()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goNext() {
      if (!this.questionnaireCompleted) return
      this.nextButtonIsLoading = true

      const eligibilityAnswers = this.convertAnswersToArrayOnly()
      const payload = { ...eligibilityAnswers, ...JSON.parse(this.suitabilityAnswers) }
      saveQuestionnaire(payload).then((res) => {
        if (!res.success) return
        this.showEligibilityResultModal = true
      })
    },
    closeEligibilityResultModal() {
      if (this.fromProfilePage) {
        if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
        this.$router.replace('/investorProfile')
      } else {
        this.showEligibilityResultModal = false
        this.showDisclaimer = true
      }
    },
    closeDisclaimerModal() {
      if (embeddedInIos()) return window.webkit.messageHandlers.questionnaireSaved.postMessage(this.propertyId)
      this.$router.replace(`/${this.nameSpaceUrl}/buy/amount/${this.propertyId}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.eligibility-questionnaire {
  min-height: 100vh;
  background-color: #F0EEF8;
  padding-bottom: 50px;
  .content-wrapper {
    padding: 0 24px;
    .page-title {
      font-size: 32px;
      line-height: 38px;
    }
  }
  .footer-buttons {
    padding: 24px;
  }
  .confetti {
    position: absolute;
    top: 1rem;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
    z-index: 200;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .eligibility-questionnaire {
    min-height: calc(100vh - 210px);
    border-radius: 12PX;
  }
}
</style>
